import React from 'react';

import { Layout } from '../../components/layout';
import SEO from '../../components/seo';

export default function FullStackDeveloper(): JSX.Element {
  return (
    <Layout>
      <SEO title="Full-Stack Developer" />
      <div className="container">
        <nav className="breadcrumb">
          <a href="/#jobs">Jobs</a>
          <span>Full-Stack Developer</span>
        </nav>
        <h2>Full-Stack Developer (Fulltime / Remote)</h2>
        <h4>The Highlights</h4>
        <ul>
          <li>
            Product development studio that goes full-stack Rails 7/Ruby 3 with
            Hotwire, Postgres and TypeScript.
          </li>
          <li>Remote-First with an office in Berlin</li>
          <li>a supportive, diverse working environment </li>
          <li>
            You: a Rails developer with more than 3 years of development
            experience and a get-things-done attitude that likes to help shaping
            the future of our company
          </li>
        </ul>
        <h4>About us</h4>
        <p>
          nerdgeschoss is a product development studio based in Berlin working
          on products for promising startups and help established companies
          innovating on their products and services. We work in agile,
          multi-disciplinary teams which helps us solve business problems for
          our clients by building the right thing. Also we value the work that
          other developers share as open source on GitHub.
        </p>
        <h4>Bring Your Whole Self to Work.</h4>
        <p>
          What makes you weird and special makes you memorable. And not only
          that, it makes you authentic, original, and enriches the whole
          workplace with your personal perspective. This is your superpower.
          Therefore we pledge to increase diversity in our company by trying to
          explicitly encourage, hire and support people from underrepresented
          groups. To reduce unfair payment, we pay salaries according to the{' '}
          <a href="/handbook/hgf" className="link">
            nerdgeschoss Human Growth Framework
          </a>
          , based on skills and achievements.
        </p>
        <h4>Remote First - Kill the busy work, get stuff done.</h4>
        <p>
          We are not only remote friendly, but remote first. While we have a
          beautiful office in Berlin Kreuzberg that you can use at any time,
          you're also free to work from wherever you want - we even have one
          employee touring Sweden in a camper van while working. We value
          productivity over busy work and try to support you in your individual
          work style as much as possible.
        </p>
        <h4>The Tech Stack</h4>
        <p>
          We believe that only deployed software is good software and that our
          customers should have working software as quickly as possible.
          Therefore we try to unify our tech stack across projects, based on
          Rails 7, Ruby 3 and Hotwire with PostgreSQL and Redis. If the need for
          more complicated frontend arises, we throw in some React with
          TypeScript. We develop APIs in GraphQL and host everything on Heroku.
        </p>
        <p>
          Apps for the AppStores are built wherever possible as PWAs/Capacitor
          or for more demanding projects with Turbo Native to give us the
          opportunity to iterate quickly across platforms.
        </p>
        <p>
          Want to learn more about our tech stack? You can read all about
          <a href="/handbook/nuts" className="link">
            going n.U.T.S - Developing with the nerdgeschoss Unified Tech Stack
          </a>
          .
        </p>
        <h4>Why it's Awesome Working with us</h4>
        <p>
          Even though nerdgeschoss exists for quite a while, we embrace change
          and improvement. You're not just an employee but a valued part of our
          constant self improvement. You want to change the way how we develop
          software? Join in! You have a great idea how to improve diversity or
          how we talk to customers? Be our guest! We will do our best to support
          you to deliver the best work you can do and make a change.
        </p>
        <h4>What we are Looking for</h4>
        <p>
          You are a Rails developer with several years of experience in the
          field with a deep understanding of the web. You don't need to be an
          expert in everything, but we expect you to be eager to learn and be
          passionate about software development in general. We value a “getting
          things done” attitude and are looking for people with strong
          self-organisation skills.
        </p>
        <p>
          Fluency in written and spoken English is required, German would be
          nice but is not required. We do not require any degrees.
        </p>
        <h4>How to get Hired</h4>
        <p>
          Send us an email to{' '}
          <a className="link" href="mailto:hiring@nerdgeschoss.de">
            hiring@nerdgeschoss.de
          </a>{' '}
          and tell us about:
        </p>
        <ul>
          <li>
            Who are you? What do you value? This is your chance to give us an
            impression on what makes you tick.
          </li>
          <li>Why do you want to work with us?</li>
          <li>
            What have you done so far? Tell us about projects that you have
            worked on, talks at meetups and conferences and open source
            involvements (contributing to other projects or with your own open
            projects).
          </li>
          <li>
            Don't forget to add your GitHub account. Point us to repositories in
            it that represent your work the most.
          </li>
        </ul>
        <p>
          We will evaluate your application as quickly as possible, usually
          coming back to you within 2 to 3 days with a proposal to have a quick
          call to get to know each other. This call is all about making sure we
          are a fit in terms of personality and attitude. Bring your questions,
          no topic about the company is off limits.
        </p>
        <p>
          If we both want to move forward, the next step is our tech interview.
          During a one hour call with the CTO we will present actual problems
          that we faced during product development. You don't need to balance a
          binary tree and you won't need to code on a whiteboard, but we want to
          see actual problem solving skills here. The interview ends with a
          short offline coding challenge that we ask you to implement within the
          day (for a skilled developer that shouldn't take more than 30min).
        </p>
        <p>
          After this we come back to you with an offer. Yes you read right -
          there's only two calls to get hired, no time-wasting processes.
        </p>
        <p>
          <a className="link" href="mailto:hiring@nerdgeschoss.de">
            Sounds interesting? Let's talk
          </a>
        </p>
      </div>
    </Layout>
  );
}
